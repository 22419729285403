@media (max-width: 767px){.tag.taxo:not(.small) {
  --tag-padding: 1.6rem;
}

.button.btn-big {
  --btn-padding: 2rem 2.4rem;
  max-width: 100%;
  width: 100%;
}

.button [class*=icon-] {
  padding-left: 1.6rem;
}

.container,
.container-small,
.container-big {
  --container-size: 100%;
}

.container-big {
  --container-big-padding: 6.4rem;
}

.col-xs-1 {
  --grid-col: 1;
}

.col-xs-2 {
  --grid-col: 2;
}

.col-xs-3 {
  --grid-col: 3;
}

.col-xs-4 {
  --grid-col: 4;
}

.col-xs-5 {
  --grid-col: 5;
}

.col-xs-6 {
  --grid-col: 6;
}

.col-xs-7 {
  --grid-col: 7;
}

.col-xs-8 {
  --grid-col: 8;
}

.col-xs-9 {
  --grid-col: 9;
}

.col-xs-10 {
  --grid-col: 10;
}

.col-xs-11 {
  --grid-col: 11;
}

.col-xs-12 {
  --grid-col: 12;
}

:root {
  --big-title-size: 2.4rem;
  --h1-size: 3.2rem;
  --h1-content-size: 2.4rem;
  --h2-size: 2.4rem;
  --h2-content-size: 1.8rem;
  --h3-size: 1.8rem;
  --h3-content-size: 1.6rem;
  --h4-size: var(--h3-size);
  --h5-size: var(--h3-size);
  --h6-size: var(--h3-size);
  --big-title-line-height: 3.1rem;
  --h1-line-height: 3.2rem;
  --h1-content-line-height: 2.4rem;
  --h2-line-height: 2.4rem;
  --h2-content-line-height: 2.4rem;
  --h3-line-height: 2.4rem;
  --h3-content-line-height: 1.8rem;
  --h4-line-height: var(--h3-line-height);
  --h5-line-height: var(--h3-line-height);
  --h6-line-height: var(--h3-line-height);
  --marg-btm-title: 2.4rem;
}

.content h1,
.content .h1 {
  font-size: var(--h1-content-size);
  line-height: var(--h1-content-line-height);
}

.highlighted-long::before {
  display: none;
}

.highlighted-long {
  white-space: normal;
}

.rounded-img:not(.big) {
  --round-size: 7rem;
}

.rounded-img .big {
  --round-size: 12.4rem;
}

.hidden-mobile {
  display: none;
}

.paragraph-wrapper {
  position: relative;
  margin-bottom: 6.2rem;
}

.paragraph-wrapper label {
  position: absolute;
  bottom: -3.9rem;
  font-weight: 600;
  padding: .4rem;
}

.paragraph-wrapper .icon-chevron-down {
  font-size: 1.2rem;
  margin-left: .4rem;
}

.paragraph-behavior:checked~label .icon-chevron-down {
  -webkit-transform: scale(-1);
  transform: scale(-1);
}

.paragraph-behavior:checked~.more-paragraph {
  --max-height: max-content;
}

.paragraph-behavior:not(:checked)~.more-paragraph {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

.more-paragraph {
  --column-count: 1;
  --max-height: calc(2.16rem * 5);
  overflow: hidden;
  max-height: var(--max-height);
}

.card-push {
  --card-grid-rows: 5.4rem auto 1fr;
}

.card-job-offer.header {
  margin-left: -0.8rem;
  margin-right: -0.8rem;
}

.searchform {
  --searchform-grid-rows: auto auto;
  row-gap: 1.6rem;
}

.tag-list-wrapper {
  position: relative;
  margin-bottom: 6.2rem;
}

.tag-list-wrapper label {
  position: absolute;
  bottom: -3.9rem;
  font-weight: 600;
  padding: .4rem;
}

.tag-list-wrapper .icon-chevron-down {
  font-size: 1.2rem;
  margin-left: .4rem;
}

.list {
  --max-height: none;
  overflow: hidden;
  max-height: var(--max-height);
}

.list li {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.list-behavior:checked~label .icon-chevron-down {
  -webkit-transform: scale(-1);
  transform: scale(-1);
}

.list-behavior:checked~.list {
  --max-height: max-content;
}

.tag-list {
  --max-height: calc(4.5rem * 5 - var(--gap-list));
}

.tag-taxo-list {
  --gap-list: 1.6rem 0.8rem;
}

.term-list {
  --column-count: 1;
  --max-height: calc(3.4rem * 9 - var(--gap-list));
}

.term-list-double {
  --column-count: 1;
  --max-height: calc(6.4rem * 8 - var(--gap-list));
}

:root {
  --header-padding: 2.4rem;
  --logo-size: var(--ico-size-m);
  --header-size: 8rem;
  --header-gap: 0;
}

#searchform-trigger .icon-close,
#searchform-trigger .icon-search {
  display: var(--searchform-trigger, none);
}

.fusion-form .searchform-content {
  display: var(--fusion-form-state, none);
}

.fusion-form #searchform-trigger {
  margin-right: 2rem;
}

.fusion-form #searchform-trigger .icon-search {
  --searchform-trigger: inline-block;
}

.fusion-form #searchform-behavior:checked~#header-navigation #searchform-trigger .icon-search {
  --searchform-trigger: none;
}

.fusion-form #searchform-behavior:checked~#header-navigation #searchform-trigger .icon-close {
  --searchform-trigger: inline-block;
}

.fusion-form #searchform-behavior:checked~main .searchform-content {
  --fusion-form-state: block;
}

#menu-trigger,
#header-navigation .logo-jobintree {
  position: relative;
  z-index: 5;
}

.nav-wrapper,
#menu-trigger .icon-close {
  display: none;
}

.nav-wrapper {
  display: grid;
  -ms-flex-line-pack: start;
  align-content: start;
  text-align: center;
  position: absolute;
  inset: 0;
  width: 100vw;
  left: -100vw;
  height: 100vh;
  opacity: 0;
  background-color: #fff;
  padding-top: 10.4rem;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}

.nav-menu {
  justify-self: end;
}

.nav-item [open] summary {
  padding-bottom: .8rem;
}

#menu-behavior:checked~#header-navigation #menu-trigger .icon-menu-burger {
  display: none;
}

#menu-behavior:checked~#header-navigation #menu-trigger .icon-close {
  display: inline-block;
}

#menu-behavior:checked~#header-navigation .nav-wrapper {
  left: 0vh;
  opacity: 1;
}

.section-hero {
  --hero-grid-columns-wrapper: 8.5rem 1fr;
  --hero-grid-rows-wrapper: 4em 4em 22rem;
  --hero-img-height: 34rem;
  --hero-marg-btm: calc(3.6rem);
}

.section-hero .searchform-title {
  grid-column: 1/3;
  grid-row: 4/4;
  padding: 1.6rem;
}

.section-hero .searchform-wrapper {
  grid-column: 1/3;
  grid-row: 3/4;
}

.header-job {
  --header-grid-columns: 1fr;
  --header-grid-rows: 1fr;
  --header-grid-gap: 3.2rem;
  --header-img-width: 12.4rem;
  --header-img-height: 12.4rem;
}

.marg-btm-xl {
  --marg-btm-xl: calc(3.2rem * 2);
}}